.res {
  p {
    text-align: center;
    margin-top: 50px;
    color: rgba(0,0,0,0.4);
    font-size: 18px;
    font-weight: 600;
  }
  
  object {
    width: 100%;
    height: 480px;
  }
}