.rvp {
  &__item {
    margin-bottom: 20px;
    border-radius: 1rem;
    padding: 20px;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, #c4c4c4 0px 0px 0px 1px inset;
    }
  }

  &__noItem {
    padding: 2rem 0;
    text-align: center;
    color: #c4c4c4;
    font-size: 18px;
  }

  &__loading-wrapper {
    padding: 2rem 0;
    text-align: center;
  }

  &__datebox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__date {
    color: #212121;
    font-size: 11px;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__main {
    display: flex;
    align-items: center;
  }

  &__name {
    font-size: 16px;
    font-weight: 500;
    color: #404040;
    margin-bottom: 12px;
    text-decoration: none;
    cursor: pointer;
  }

  &__details {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__detailItem {
    margin-right: 15px;
    font-size: 11px;
    color: #404040;
    white-space: nowrap;
  }

  &__detailNumber {
    background-color: #EFEFEF;
    border-radius: 50%;
    font-weight: 600;
    color: #898989;
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &__workflowHeader {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;

    &Name {
      margin-left: 10px;
      font-size: 16px;
      font-weight: 500;
      color: #404040;
    }
  }
}