.wk {
    &__emailRecommendation {
        background-color: #fff;
        border-top-right-radius: 30px;
        width: 50%;
        overflow-y: auto;
        margin-left: 1rem;

        &::-webkit-scrollbar {
            width: 5px;
            background: #E2E2E2;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: #8CC0E3;
            border-radius: 4px;
        }

        &-closed {
            margin: auto 0 auto 1rem;
            background-color: #E1F3FF;
            padding: 30px 8px;
            border-radius: 25px 0px 0px 25px;
            height: fit-content;
            text-align: center;
            cursor: pointer;

            &Text {
                margin-top: 0.5rem;
                color: #479BD2;
                writing-mode: tb-rl;
                transform: rotate(-180deg);
            }
        }

        &-icons {
            display: flex;
            align-items: center;
        }

        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 16px;
            border-bottom: 1px solid #D9D9D9;

            &-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;

                svg {
                    cursor: pointer;
                    width: 20px;
                    height: 20px;
                }
            }
        }

        &-copy {
            width: 18px;
            height: 18px;
            cursor: pointer;
            margin-right: 10px;

            &:last-child {
                width: 20px;
                height: 20px;
                margin-right: 0;
            }
        }

        &-title {
            color: #747474;
        }

        &-tabs {
            padding: 0 16px;
        }

        &-btn {
            background-color: #479BD2;
            border-radius: 2rem;
            padding: 0.5rem 2rem;
            font-weight: bold;
            color: #fff;
            display: block;
            width: fit-content;
            margin: 1rem auto;
        }
    }
}