.nav {
    &__container {
        padding: 0 10px 18px;
    }

    &__body {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 0.5rem;
    }

    &__searchBar {
        width: 180px;
        box-sizing: border-box;
        border: 2px solid #ccc;
        border-radius: 30px;
        font-size: 14px;
        background-color: white;
        background-image: url("../../assets/img/search.svg");
        background-position: 9px 5px;
        background-repeat: no-repeat;
        background-size: 24px;
        padding: 5px 20px 5px 40px;
        -webkit-transition: width 0.4s ease-in-out;
        transition: width 0.4s ease-in-out;

        &:focus {
            width: 250px;
        }
    }

    &__link {
        color: #6b6b6b !important;
        font-size: 16px;
        cursor: pointer;
    }

    &__prodWalk {
        color: rgb(51, 77, 110);
        font-weight: 600;
        border-radius: 16px;
        background: rgb(255, 255, 255);
        box-shadow: rgba(16, 156, 241, 0.24) 0px 4px 10px 0px;

        @media screen and (max-width: 1200px) {
            height: auto;
            width: 160px;

            >span {
                white-space: normal;
            }
        }

        @media screen and (max-width: 1200px) {
            width: 130px;
        }
    }

    &__disable {
        cursor: no-drop !important;
        opacity: 0.2;
    }

    &__disable {
        cursor: no-drop !important;
        opacity: 0.2;
    }
}