.pmodal {

  &__container {
    margin-top: 20px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  &__label {
    width: 100%;
    margin: 5px 15px;
    color: #6B6B6B;
    font-size: 17px;
    font-weight: 500;
  }

  &__input {
    background: #FBFBFB;
    border-radius: 13px;
    font-size: 16px;
    padding: 15px 25px;
    width: calc(100% - 20px);
    margin: 0 10px;

    &::placeholder {
      color: #CFCFCF;
      font-size: 16px;
    }
  }

  &__inputBox {
    width: 50%;
  }

  &__select {
    width: calc(100% - 20px);
    margin: 0 10px;
    padding: 9px 0;
    background-color: #FBFBFB;
    border-radius: 13px;

    p {
      color: #CFCFCF;
      font-size: 16px;
    }
  }

  &__textarea {
    width: calc(100% - 20px);
    margin: 0 10px;
    padding: 9px 0;
    background-color: #FBFBFB;
    border-radius: 13px;
    outline: none;
    border: none;
    height: 80px;
    padding: 10px 20px;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    resize: none;

    &:active, &:focus {
      outline: none;
      border: none;
    }
  }

  &__checkbox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 10px;
    margin-left: 15px;

    & label {
      margin-bottom: 5px;
      margin-inline-start: 0 !important;
    }

    & span {
      color: #757575;
      font-size: 13px;
      font-family: "Poppins", sans-serif;
    }
  }
  
  &__memberBox {
    margin: 15px;
    width: 300px;
  }

  &__memberInput {
    background-color: #FBFBFB;
    border-radius: 13px;
    padding: 9px;

    & > div > div {
      background-color: #FBFBFB !important;
      border: none !important;
    }

    & > div {
      & > span:last-child {
        color: #CFCFCF;
        font-size: 16px;
      }
    }
  }

  &__btnBox {
    text-align: right;
  }
}