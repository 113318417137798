.team {

  &__actionBar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 20px;
  }

  &__actionLeft {
    display: flex;
    align-items: flex-start;
  }

  &__userBox {
    padding: 9px 0;

    p:first-child {
      font-weight: 500;
      font-size: 26px;
      color: #334D6E;
    }

    p:last-child {
      font-weight: 500;
      font-size: 18px;
      color: #90A0B7;
    }
  }

  &__dropdown {
    background-color: #FAFAFA;
    border: 2px solid #BFBFBF;
    border-radius: 9px;
    color: #334D6E;
    font-size: 16px;
    font-weight: 500;
    padding: 10px;
    margin: 10px 0 0 20px;

    img {
      margin-left: 10px;
    }
  }

  &__link {
    font-weight: 500;
    font-size: 16px;
    color: #334D6E;

    display: flex;
    align-items: center;

    img {
      margin-left: 15px;
    }
  }

  &__footer {
    margin: 1rem 0;
  }

  &__footerBody {
    background-color: #fff;
    padding: 20px;
    border-radius: 30px;
    box-shadow: 4px 8px 18px rgba(0, 0, 0, 0.25);
  }

  &__footerTitle {
    margin: 15px 0;
    padding: 0 10px;
    font-weight: 500;
    font-size: 20px;
    color: #334D6E;
  }

  &__footerBody {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__input {
    input {
      background: #FFFFFF;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 17px;
      padding: 10px;
      font-size: 16px;
      color: #000;
      width: 250px;

      &::placeholder {
        font-weight: 500;
        font-size: 16px;
        color: #C6C6C6;
      }
    }
  }

  &__footerSelect {
    width: 160px;
    padding: 5px 9px;
    background-color: #FFF;
    border-radius: 17px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);

    span {
      font-weight: 500 !important;
      font-size: 16px !important;
      color: #000;
    }
  }

  &__newInput {
    background: #F2F2F2;
    border-radius: 15px;
    padding: 10px;
    font-size: 18px;
    width: 100%;
    margin-bottom: 20px;

    &::placeholder {
      font-size: 18px;
      color: #90A0B7;
    }
  }

  &__newBtnBox {
    text-align: right;
  }
}

.team__inputContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}