.sp {
    &__relevanceLoader {
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100vh - 323px);
    }

    &__item {
        margin-top: 10px;
        overflow-y: auto;
        padding-right: 10px;

        &::-webkit-scrollbar {
            width: 5px;
            background: #E2E2E2;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: #8CC0E3;
            border-radius: 4px;
        }
    }
}