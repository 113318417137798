.sp {
    &__tooltip {
        padding: 6px 8px;

        &Wrapper {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
            gap: 1rem;
        }

        &Item {
            h2 {
                font-weight: 500;
                font-size: 14px;
                color: #6B6B6B;
            }

            p {
                font-size: 12px;
                color: #6B6B6B;
            }

            ul {
                font-size: 12px;
                color: #6B6B6B;
                padding-inline-start: 14px;
            }
        }
    }
}