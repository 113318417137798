.loader {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: 30px;
    font-weight: 600;
    color: #9FA0A0;
  }
}