.chatBubble {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    width: 70%;
    word-wrap: break-word;
}

.floatLeft,
.floatRight {
    border-radius: 50px;
    border: 1px solid;
    display: flex;
    height: fit-content;
    padding: 6px;
    margin-right: 10px;
    font-weight: 700;
}

.floatRight {
    float: right;
    margin-left: 10px;
    margin-right: 0;
}

.candidateContainer {
    display: flex;
    padding: 10px;
    flex-direction: column;
    margin-bottom: 20px;
}

.candidate {
    padding: 10px;
    margin-bottom: 10px;
}

.candidate,
.chatBubble {
    /* opacity: 0.8; */
    font-size: 14px;
    line-height: 25px;
}