.rvp {

  &__container {
    background: #fff;
    border-radius: 30px;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__titlebox {
    border-bottom: 3px solid #EBEFF2;
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    color: #404040;
    padding: 20px 20px 10px 20px;
  }

  &__body {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 5px;
      background: #E2E2E2;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #8CC0E3;
      border-radius: 4px;
    }
  }

  &__item {
    margin-bottom: 20px;
    cursor: pointer;
  }

  &__datebox {
    display: flex;
    justify-content: flex-end;
  }

  &__date {
    color: #212121;
    font-size: 11px;
  }

  &__main {
    display: flex;
    align-items: center;
  }

  &__user {
    padding: 20px;
    border-radius: 50%;
    color: #AAAAAA;
    background: #ECECEC;
    width: 50px;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 700;
    font-size: 20px;
    margin-right: 20px;
  }

  &__name {
    font-size: 16px;
    color: #404040;
    margin-bottom: 20px;
    text-decoration: none;
  }

  &__details {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__detailItem {
    margin-right: 15px;
    font-size: 11px;
    color: #404040;
    white-space: nowrap;
  }

  &__detailNumber {
    background-color: #EFEFEF;
    padding: 5px;
    border-radius: 10px;
    font-weight: 600;
    color: #898989;
  }

  &__no-data {
    &-img {
      display: block;
      width: auto;
      height: 300px;
      margin: 0 auto;
    }

    &-text {
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      letter-spacing: 0.01em;
      color: rgba(146, 146, 146, 0.7);
    }
  }
}