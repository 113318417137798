.modal {
    &__selectBox {
        // margin-bottom: 20px;
    }

    &__select {
        width: 250px;
        cursor: pointer;
        padding: 5px;
        background-color: #fbfbfb;
        border-radius: 13px;

        span {
            color: #767474;
            font-size: 16px;
            font-weight: 700;
            display: flex;
            align-items: center;
        }
    }

    &__saveWk {
        display: inline-flex;
        align-items: center;
        margin-right: 2rem;

        p {
            color: #787878;
            margin-right: 10px;
            flex-shrink: 0;
        }
    }

    &__workflowName {
        padding: 0.5rem 1rem;
        background-color: #f8f8f8;

        &-label {
            display: inline-block;
            margin-top: 4px;
            span {
                color: red;
            }
        }
    }

    // &__dateBox {
    //   margin-bottom: 30px;
    // }

    &__btnBox {
        display: inline-flex;
        align-items: center;
        text-align: right;
    }
}

.react-select-menu {
    z-index: 2 !important;
}

.react-select-container {
    font-size: 14px;
}
