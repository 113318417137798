.sp {
  height: 100%;

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__statistics {
    display: inline-block;
    background-color: #479BD2;
    margin: 0 10px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 32px;
    text-decoration: none;
    color: #fff;
    padding: 6px 16px;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

    &:hover {
      background-color: #4096ff;
    }
  }

  &__primaryBtn {
    background-color: #479BD2;
    margin: 0 10px;
    font-weight: 600;
  }

  &__bodyAction {
    &List {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &Ctas {
      display: inline-flex;
      align-items: center;
    }
  }

  &__bodyActionItem {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #6C6C6C;
    cursor: pointer;

    &:nth-child(1) {
      img {
        width: 28px;
        height: 28px;
      }
    }

    &:nth-child(2) {
      img {
        width: 20px;
        height: 20px;
      }
    }

    img {
      margin: 0 4px;
      display: inline-block;
      width: 24px;
      height: 24px;
    }

    &-wrapper {
      display: flex;
      align-items: center;
    }
  }

  &__link {
    color: #6B6B6B;
    font-size: 14px;
    cursor: pointer;
  }

  &__legends {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__legendItem {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;

    p {
      color: #787878;
      font-weight: 500;
      font-size: 15px;
    }
  }

  &__legendColor {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
  }

  &__actionbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #fff;
    border-radius: 12px;
    padding: 14px;
    margin-top: 16px;
  }

  &__actionItem {
    color: #334D6E;
    font-size: 16px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;

    &-filter {
      cursor: pointer;
    }

    span {
      color: #90A0B7;
      font-weight: 400;
    }

    &-img {
      margin-left: 6px;
      width: 10px;
      height: 10px;
    }
  }

  &__body {
    background-color: #fff;
    padding: 10px 20px;
    border-radius: 12px 12px 0 0;
    margin-top: 24px;
    flex: 1;
  }

  &__checkbox {
    color: #334D6E;
    font-size: 14px;
    font-weight: 500;
  }

  &__filter {
    color: #6C6C6C;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    margin-left: 2rem;
    display: flex;
    align-items: center;

    img {
      display: inline-block;
      width: 20px;
      height: 20px;
    }
  }

  &__bodyAction {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }

  &__btnBox {
    text-align: right;
    margin-top: 20px;
  }

  &__disabled {
    & .sp__bodyActionItem {
      opacity: 0.5;
      // cursor: not-allowed;
    }
  }

  &__active-relevance {
    background-color: #E1F3FF;
    padding: 2px;
    border-radius: 5px;
  }

  &__paginationPrompt {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(241, 243, 244, 0.871);
    padding: 6px 16px;
    min-height: 48px;
    border-radius: 8px;
    margin-top: 10px;

    &-text {
      font-size: 0.875rem;
      color: #5f6368;
      margin-right: 0.25rem;
    }

    &-btn {
      cursor: pointer;
      background-color: transparent;
      outline: none;
      font-size: 0.875rem;
      color: #479BD2;
      position: relative;
      transition-duration: .15s;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      padding: 4px 8px;
      border-radius: 4px;

      &:hover {
        background-color: rgb(71, 155, 210, 0.1);
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-right {
      display: flex;
      align-items: center;
      background-color: #EDEDED;
      padding: 6px;
      border-radius: 25px;
    }

    &Wrapper {
      display: inline-flex;
      align-items: center;
    }
  }

  &__heading {
    display: flex;
    align-items: center;

    >p {
      color: #334D6E;
      font-size: 20px;
      font-weight: 500;
    }
  }

  &__headingBtnBox {
    display: flex;
    align-items: center;
    margin: 0 10px;
  }

  &__headingBtn {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0 10px;
  }

  &__viewTab {
    display: inline-flex;
    align-items: center;
    color: #AEAEAE;
    font-size: 14px;
    font-weight: 500;
    border-radius: 18px;
    transition: 0.3s all ease-in-out;
    padding: 4px 10px;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    &-active {
      color: #479BD2;
      background-color: #fff;
    }
  }

  &__createDate {
    color: #90A0B7;
    font-size: 12px;
  }

  &__projectCreation {
    display: inline-flex;
    align-items: center;
    margin-left: 10px;

    p {
      background-color: #fff;
      font-size: 12px;
      font-weight: 500;
      font-style: italic;
      padding: 5px 10px;
      width: fit-content;
      border: 1.5px solid #088397;
      border-radius: 8px;
    }
  }

  &__creditsRewardTitle {
    background:
      -webkit-linear-gradient(92.1deg, #E44E4E -2.91%, #FFE600 101%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}