.wmodal {

  &__container {
    padding: 20px;
  }

  &__inputBox {
    margin-bottom: 20px;
  }

  &__input {
    font-size: 16px;
    background-color: #FBFBFB;
    border-radius: 13px;
    padding: 10px 20px;
    width: 100%;

    &::placeholder {
      color: #CFCFCF;
      font-size: 16px;
    }
  }

  &__label {
    font-size: 16px;
    color: #A8A8A8;
    margin-bottom: 20px;
    display: block;
  }

  &__checkboxGroup {
    margin-bottom: 30px;

    & label {
      margin-bottom: 5px;
    }

    & span {
      color: #757575;
      font-size: 13px;
      font-family: "Poppins", sans-serif;
    }
  }

  &__dateBox {
    margin: 10px 0;
  }

  &__memberBox {
    margin: 15px;
    width: 300px;
  }

  &__memberInput {
    background-color: #FBFBFB;
    border-radius: 13px;
    padding: 9px;

    & > div > div {
      background-color: #FBFBFB !important;
      border: none !important;
    }

    & > div {
      & > span:last-child {
        color: #CFCFCF;
        font-size: 16px;
      }
    }
  }

  &__btnBox {
    text-align: right;
  }
}