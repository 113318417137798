.profileContainer {
    display: flex;
}

.disable {
    background: #C4C4C4 !important;
}

.heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    color: #6B6B6B;
    padding-left: 40px;
    padding-bottom: 34px;
    display: block;
}

.fieldContainer,
.fieldValueContainer {
    display: flex;
    flex-direction: column;
}

.fieldValueContainer {
    width: 50%;
}

.fieldContainer {
    padding-right: 70px;
}

.field {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.01em;
    color: #334D6E;
    margin-bottom: 17px;
    /* flex: 3; */
}

.fieldValue {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #A4A4A4;
    padding: 10px 0;
    border-bottom: 1px solid;
    /* flex: 7; */
}

.pencilImg {
    width: 16px;
    float: right;
    margin-top: 5px;
    cursor: pointer;
}