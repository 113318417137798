.table {
  &__legend {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px;
  }

  &__name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__text {
    font-family: 'Poppins', sans-serif;
    color: #404040;
    font-size: 15px;
    font-weight: 500;
  }

  & th {
    color: #334D6E !important;
    font-size: 18px;
    font-weight: 500 !important;
  }

  & td {
    cursor: pointer;
  }

  &__select {
    width: 150px;
    background: #FFFFFF;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 17px;

    span {
      font-weight: 600;
      font-size: 15px;
      color: #C6C6C6;
    }
  }
}