.modal {

  &__container {
    margin: 20px 0px;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  &__dateBox {
    width: 300px;
    height: 200px;
    text-align: center;
  }

  &__btnBox {
    align-self: center;
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}