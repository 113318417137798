.pro {
  &__legendBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    margin-top: 30px;
  }

  &__link {
    color: #6B6B6B !important;
    font-size: 16px;
    cursor: pointer;
  }

  &__legend {
    margin-right: 15px;
    display: flex;
    align-items: center;

    div {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-right: 10px;
    }

    p {
      color: #787878;
      font-size: 13px;
      font-weight: 500;
    }
  }

  &__filter {
    display: flex;
    align-items: center;
    color: #6C6C6C;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }

  &__actionRight {
    display: flex;
    align-items: center;
  }

  &__deleteBox {
    margin-right: 10px;
    display: flex;
    align-items: center;

    img {
      width: 26px;
      height: 26px;
      cursor: pointer;
    }
  }
}