.spcard {
    &__card {
        &-content {
            display: flex;
        }

        &-wrapper {
            display: flex;
            justify-content: center;

            &-main {
                flex: 1;
                display: flex;
                flex-direction: column;
            }
        }
    }

    &__cardLeft {
        width: 50%;
        margin-right: 2rem;
    }

    &__skillsWrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        &-heading {
            font-size: 12px;
            color: #6c6c6c;
            margin-right: 0.4rem;
        }
    }

    &__cardRight {
        flex: 1;

        &Top {
            position: relative;
            margin-bottom: 1rem;
        }
    }

    &__cardLast {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-left: 1rem;
        min-width: 155px;
    }

    &__candStatus {
        background-color: #ffd361;
        border: 0.5px solid #000000;
        text-transform: uppercase;
        display: inline-flex;
        align-items: center;
        padding: 4px 8px;
        color: #656565;
        font-size: 14px;
        margin-top: 10px;

        p {
            margin-right: 8px;
        }
    }

    &__cardTop {
        display: inline-flex;
        align-items: center;
    }

    &__item {
        margin-top: auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;

        &-last {
            margin-bottom: 0;
        }
    }

    &__location {
        margin-top: auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 12px;
    }

    &__itemLeft {
        min-width: 50px;
        margin-right: 20px;

        p {
            font-size: 12px;
            color: #9d9d9d;
            font-weight: 500;
        }
    }

    &__itemRight {
        flex: 1;
    }

    &__imgBox {
        padding: 20px;
        border-radius: 50%;
        background-color: #ececec;
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    &__imgText {
        font-weight: 700;
        font-size: 20px;
        color: #aaaaaa;
    }

    &__linkedInLogo {
        display: inline-block;
        width: 24px;
        height: 24px;
    }

    &__relevanceText {
        color: #6c6c6c;
        padding: 10px 20px;
        background-color: #e1f3ff;
        border-radius: 0.5rem;
        margin-top: 1.5rem;
    }

    &__visaItem {
        display: flex;
        align-items: center;

        img {
            width: 24px;
            height: 24px;
            transform: translateX(6px);
        }

        &-text {
            color: #000;
            background-color: #e1f3ff;
            padding: 2px 6px 2px 8px;
            font-size: 12px;
            font-weight: 600;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }

    &__showHideEmail {
        display: flex;

        &-btn {
            border-radius: 8px;
            border: 1px solid #838383;
            padding: 0.25rem 1rem;
            color: #6c6c6c;
            font-family: inherit;
            background-color: transparent;
            margin-right: 1rem;
            cursor: pointer;
            height: fit-content;

            &:focus {
                border: 1px solid #838383;
            }
        }

        &-text {
            font-size: 15px;
            font-weight: 500;
            color: #838383;
        }
    }

    &__imgColor {
        position: absolute;
        left: -2.5px;
        top: -2.5px;

        border-radius: 50%;
        width: 20px;
        height: 20px;
    }

    &__name {
        font-size: 15px;
        color: #000;
        font-weight: 600;
        margin-bottom: 0.75rem;

        >span {
            background-color: #e1f3ff;
            padding: 0.25rem 0.5rem;
            border-radius: 4px;
            font-weight: 500;
            font-size: 12px;
            color: #656565;
            margin-left: 1rem;
        }
    }

    &__linkedIn {
        display: inline-flex;
        align-items: center;
        margin-left: 12px;
    }

    &__desc {
        font-size: 14px;
        color: #6c6c6c;
    }

    &__info {
        display: flex;
        flex-wrap: wrap;
    }

    &__infoItem {
        color: #6c6c6c;
        font-size: 12px;
        display: inline-flex;
        align-items: center;
    }

    &__infoCompanies {
        color: #6c6c6c;
        font-size: 12px;
        display: inline-flex;

        &-title {
            flex-shrink: 0;
        }
    }

    &__divider {
        width: 2px;
        background-color: #efefef;
        align-self: stretch;
        margin: 0 10px;
    }

    &__link {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 5px;
        width: 100%;

        a {
            text-decoration: none;
            margin-left: 8px;
            flex: 1;
            color: #707070;
        }
    }

    &__professionalEmail {
        font-size: 15px;
        font-weight: 500;
        color: #838383;
        margin-top: 0.5rem;
    }

    &__demo {
        background-color: #088397;
        font-size: 12px;
        font-weight: bold;
        color: #fff;
        width: fit-content;
        padding: 0 4px;
        border-radius: 2px;
        margin-bottom: 0.5rem;
    }
}