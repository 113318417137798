.orgContainer {
    height: 100%;
}

.container {
    background: #fafafa;
    background-color: #ffffff;
    border-radius: 20px;
    height: 92%;
    
}

.bodyContainer {
    height: 95%;
    overflow-y: auto;
    padding: 10px 1px;
}

.orgCard {
    padding: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #f0f0f0;
}

.orgCardItem {
    padding: 10px;
}

.headerContainer {
    padding: 10px;
    color: #334D6E !important;
    font-size: 18px;
    font-weight: 500 !important;
    background: #fafafa;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 20px 20px 0px 0px;
}

.header {
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
}

.searchInput {
    margin-right: 15px !important;
    width: 210px;
}

.orgCount {
    display: inline;
    margin-right: 15px;
}