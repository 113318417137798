.smsg {
  max-height: calc(100vh - 300px);
  // overflow-y: scroll;

  &__container {
    background: #fff;
    border-radius: 30px;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  &__link {
    color: #6B6B6B !important;
    font-size: 16px;
    cursor: pointer;
  }

  &__headerTop {
    display: flex;
    align-items: center;
  }

  &__userDetails {
    flex: 1;

    display: flex;
    align-items: center;
  }

  &__header {
    background-color: #efefef;
    padding: 10px 20px;
    border-radius: 30px 30px 0 0;
  }

  &__leftIcon {
    margin-right: 20px;
    display: inline-flex;
    width: 14px;
    height: auto;
    align-items: center;
    cursor: pointer;
  }

  &__addIcon {
    display: inline-flex;
    align-items: center;

    img {
      margin-left: 10px;
      cursor: pointer;
    }
  }

  &__img {
    width: 40px;
    height: 40px;
    margin-right: 1rem;
  }

  &__name {
    color: #212121;
    font-size: 16px;
    font-weight: 500;
  }

  &__desc {
    color: #212121;
    font-size: 14px;
    font-weight: 300;
  }

  &__tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
  }

  &__candStatus {
    background-color: #FFD361;
    border: 0.5px solid #000000;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    padding: 4px 8px;
    color: #656565;
    font-size: 14px;
    margin-right: 10px;

    p {
      margin-right: 8px;
    }
  }

  &__tab {
    flex: 1;
    color: #747474;
    font-size: 16px;
    font-weight: 500;
    border: 0.5px solid #C9C9C9;
    text-align: center;
    padding: 0.3rem 1rem;
    border-radius: 0.5rem;
    cursor: pointer;

    &:hover {
      background: #F2F6FF;
    }

    &--active {
      background: #F2F6FF;
    }
  }

  &__body {
    flex: 1;
    padding: 10px 0 20px;
    border-bottom: 3px solid #EBEFF2;
    margin-right: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 480px);

    &::-webkit-scrollbar {
      width: 5px;
      background: #E2E2E2;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #8CC0E3;
      border-radius: 4px;
    }
  }

  &__msg {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    padding: 1rem;

    // &--user {
    //   align-items: flex-end;
    // }
  }

  &__item {
    display: flex;
    align-items: center;
    max-width: 100%;

  }

  &__msg--user {
    & .smsg__item {
      width: 100%;
      flex-direction: row-reverse;
      text-align: right;
    }

    & .smsg__msgText {
      color: #479BD2;
    }
  }

  &__msgDate {
    font-size: 10px;
    font-weight: 500;
    color: #72777A;
  }

  &__msgProfile {
    width: 30px;
    height: 30px;
    margin: 0 15px;
    align-self: flex-start;

    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-top: 0.3rem;
    }
  }

  &__msgText {
    color: #303437;
    font-size: 12px;
  }

  &__footer {
    margin-top: auto;
    padding: 10px;
  }

  &__inputBox {
    margin-bottom: 10px;
  }

  &__quill {
    &>div:nth-child(2)>div:nth-child(1) {
      height: 70px !important;
    }
  }

  &__input {
    border: none;
    outline: none;
    background: #F7F7F7;
    border-radius: 6px;
    color: #212121;
    padding: 10px;
    width: 100%;
    font-family: "Poppins", sans-serif;
    font-size: 12px;


    &:focus,
    &:active {
      border: none;
      outline: none;
    }
  }

  &__btnBox {
    text-align: right;
  }
}