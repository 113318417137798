.tab {

  &__container {
    padding: 20px;
  }

  &__btnBox {
    text-align: right;
    margin-top: 20px;
  }

  &__body {
    margin-top: 10px;
  }

  &__selectBox {
    min-width: 300px;
  }

  &__select {
    width: calc(100% - 20px);
    margin: 0 10px;
    padding: 9px 0;
    background-color: #FBFBFB;
    border-radius: 13px;

    & > div {
      & > span:last-child {
        color: #CFCFCF;
        font-size: 16px;
      }
    }
  }

  &__actionBox {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__imgBox {
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }

  &__ccInputBox {
    display: flex;
    align-items: center;

    label {
      font-weight: 600;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.54);
    }

    input {
      width: 300px;
      margin-left: 10px;
      padding: 10px;
    }
  }

  &__quillBox {
    margin-top: 20px;
  }

  &__ccSubtitle {
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    padding-top: 5px;
    font-weight: 500;
    font-size: 12px;
    color: rgba(108, 108, 108, 0.5);
    width: 400px;
  }

  &__prevBox {
    margin-top: 50px;
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    padding-top: 10px;
  }

  &__prevText {
    font-weight: 500;
    font-size: 18px;
    color: rgba(108, 108, 108, 0.5);
  }

  &__emailBox {
    text-align: center;
    margin-top: 30px;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  &__profile {
    margin-right: 20px;
    padding-top: 10px;
  }

  &__desc {
    max-width: 500px;
  }

  &__img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  &__text {
    color: #6C6C6C;
    font-size: 12px;
    margin-bottom: 10px;
  }

  &__date {
    color: rgba(108, 108, 108, 0.7);
    font-size: 10px;
  }
}