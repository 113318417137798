.spcard {
    &__container {
        display: flex;
        align-items: center;
        margin: 20px 0;
    }

    &__card {
        flex: 1;
        margin-left: 30px;
        cursor: pointer;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 20px 30px;
    }
}