.hyperQuill :global(.ql-editor) {
    max-height: 100% !important;
    min-height: 95% !important;
}

.hyperQuill :global(.ql-container) {
    height: 400px !important;
}

@media screen and (max-height: 900px) {
    .hyperQuill :global(.ql-container) {
        height: 360px !important;
    }
}

@media screen and (max-height: 850px) {
    .hyperQuill :global(.ql-container) {
        height: 330px !important;
    }
}

@media screen and (max-height: 800px) {
    .hyperQuill :global(.ql-container) {
        height: 270px !important;
    }
}

@media screen and (max-height: 750px) {
    .hyperQuill :global(.ql-container) {
        height: 200px !important;
    }
}

.candidateListContainer {
    max-height: 500px;
    overflow: auto;

    @media screen and (max-height: 900px) {
        max-height: 450px;
    }

    @media screen and (max-height: 800px) {
        max-height: 380px;
    }
}
