.nmodal {
  &__container {
    padding: 20px;
  }

  &__btnBox {
    text-align: right;
    margin-top: 20px;
  }

  &__icon {
    display: inline-block;
    width: 20px;
    height: 20px;
  }

  &__body {
    margin-top: 30px;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__profile {
    margin-right: 20px;
    padding-top: 10px;
  }

  &__desc {
    margin-right: 1rem;
    max-width: 500px;
  }

  &__img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  &__text {
    color: #6C6C6C;
    font-size: 12px;
    margin-bottom: 0.5rem;
  }

  &__date {
    color: rgba(108, 108, 108, 0.7);
    font-size: 10px;
  }

  &__avatar {
    padding: 20px;
    border-radius: 50%;
    background-color: #c4c4c4;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    font-weight: bold;
    color: #000;
  }
}