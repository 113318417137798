.projectGrid {
    &__linkedInColumn {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        img {
            display: inline-block;
            width: 18px;
            height: 18px;
        }
    }

    &__emailBox {
        display: inline-flex;
        align-items: center;
    }

    &__linkedInImage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: inherit;

        p {
            white-space: pre;
        }

        img {
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-left: 4px;
        }
    }

    &__position {
        font-weight: 600;
    }

    &__org {
        color: #808080;
    }

    &__professionalEmail {
        margin-top: 1rem;
    }
}