.rem {

  &__container {
    margin: 20px 0;
  }

  &__input {
    padding: 0.5rem 1rem;
    border-radius: 13px;
    background-color: #F8F8F8;
    font-size: 14px;
    color: #6B6B6B;
    width: 100%;
  }

  &__templateBtns {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  &__body {
    padding: 20px;
    border-radius: 30px;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }

  &__btnBox {
    margin: 10px 0;
  }

  &__legendBox {
    display: flex;
    justify-content: center;

    position: relative;

    &::before {
      content: "";
      width: 4px;
      height: 20px;
      position: absolute;
      top: -20px;
      left: 50%;
      background-color: #479BD2;
    }

    &-start {
      &::before {
        content: "";
        width: 0;
        height: 0;
      }
    }
  }

  &__legend {
    background: #EEF0F2;
    border: 1px solid #479BD2;
    border-radius: 10px;
    color: #479BD2;
    font-size: 16px;
    padding: 5px 10px;
    cursor: pointer;
  }

  &__invert {
    transform: rotate(180deg);
  }

  &__link {
    color: #6B6B6B !important;
    font-size: 16px;
    cursor: pointer;
  }

  &__selectBox {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  &__select {
    display: inline-flex;
    cursor: pointer;
    padding: 5px 10px;
    background-color: #F6F6F6;
    border-radius: 7px;
    height: 100%;

    &-personalise {
      display: inline-block;
      width: 28px;
      height: 28px;
      margin-left: 10px;
    }

    p {
      color: #767474;
      font-size: 12px;
      font-weight: 700;
      display: flex;
      align-items: center;
    }
  }

  &__templateBox {
    display: flex;
    align-items: center;
  }

  &__actionbar {
    display: flex;
    align-items: center;

    position: absolute;
    top: 7px;
    right: 0;
  }

  &__actionBtn {
    font-weight: 500;
    font-size: 16px;
    color: #8D8D8D;
    margin: 0 10px;

    display: flex;
    align-items: center;
    cursor: pointer;

    img {
      width: 20px;
      height: 20px;

      &:last-child {
        width: 24px;
        height: 24px;
      }
    }
  }

}