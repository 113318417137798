.integrationPage {
    &__input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;

        label {
            flex-shrink: 0;
            margin-right: 1rem;
        }
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}