.table {
  &__legend {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px;
  }

  &__name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__text {
    font-family: 'Poppins', sans-serif;
    color: #404040;
    font-size: 15px;
    font-weight: 500;
  }

    &__subText {
      font-family: 'Poppins', sans-serif;
    color: #C0C0C0;
    font-size: 14px;
  }

  & th {
    color: #334D6E !important;
    font-size: 18px;
    font-weight: 500 !important;
  }

  & td {
    cursor: pointer;
  }
}