.nmodal {
    &__container {
        padding-top: 20px;
    }

    &__loader {
        text-align: center;
    }

    &__btnBox {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: right;
        margin-top: 10px;
    }

    &__body {
        margin-top: 30px;
    }

    &__item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
    }

    &__profile {
        margin-right: 20px;
        padding-top: 10px;
    }

    &__desc {
        max-width: 500px;
    }

    &__img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    &__text {
        color: #6c6c6c;
        font-size: 12px;
        margin-bottom: 10px;
    }

    &__date {
        color: rgba(108, 108, 108, 0.7);
        font-size: 10px;
    }

    &__checkBoxText {
        font-weight: 500;
        font-size: 12px;
        margin-right: 20px;
        color: rgba(108, 108, 108, 0.5);
    }

    &__action {
        display: flex;
        align-items: center;
        margin-left: 20px;
        margin-top: 15px;
    }

    &__imgBox {
        margin-right: 20px;
        cursor: pointer;

        img {
            width: 20px;
            height: 20px;
        }
    }

    &__avatar {
        padding: 20px;
        border-radius: 50%;
        background-color: #c4c4c4;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;
        font-weight: bold;
        color: #000;
    }
}