.exportButtonContainer {
    background-color: #ffffff;
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: end;
}

.exportButton {
    margin-right: 10px !important;
}

.sortByContainer {
    background-color: #ffffff;
    width: 150px;
    float: right;
}

.userCount {
    display: inline;
    font-size: 14px !important;
    margin-right: 15px !important;
    opacity: 0.7;
}

.link {
    margin-left: 10px;
    cursor: pointer;
    color: #479bd2 !important;
}

.nameContainer {
    display: flex;
    justify-content: center;
}