.app {
    &__mailalert {
        width: 100%;
        padding: 7.5px 20px;
        color: #fff;
        background-color: #d14b4b;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;

        &-text {
            font-size: 14px;
            margin-right: 30px;

            span {
                cursor: pointer;
                text-decoration: underline;
            }
        }

        &-cross {
            cursor: pointer;
            width: 20px;
            height: 20px;
        }
    }

    &__mailprovider {
        &-gmail {
            display: inline-block;
            height: 30px;
            width: auto;
        }

        &-outlook {
            display: inline-block;
            height: 30px;
            width: auto;
        }

        &-text {
            margin-left: 10px;
            font-size: 20px;
        }
    }

    &__warning-text {
        position: absolute;
        margin-top: 54px;
        font-size: 12px;
    }

    &__btnbox {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
