.sp {
    height: 100%;

    &__container {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__primaryBtn {
        background-color: #479BD2;
        margin: 0 10px;
        font-weight: 600;
    }

    &__heading {
        color: #334D6E;
        font-size: 26px;
        font-weight: 500;
        display: flex;
        align-items: center;
    }

    &__bodyAction {
        &List {
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &Ctas {
            display: inline-flex;
            align-items: center;
        }
    }

    &__headerWrapper {
        display: inline-flex;
        align-items: center;
    }

    &__projectCreation {
        display: inline-flex;
        align-items: center;
        margin-left: 10px;

        p {
            background-color: #fff;
            font-size: 12px;
            padding: 5px 10px;
            width: fit-content;
        }
    }

    &__refreshIcon {
        display: inline-flex;
        align-items: center;
        margin-left: 4px;
        cursor: pointer;
    }

    &__bodyActionItem {
        display: flex !important;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        color: #8D8D8D;
        cursor: pointer;
        border: none;
        box-shadow: none;
        background: none;
        padding: 0;

        button {
            display: inline-flex;
            align-items: center;
            border: none;
            box-shadow: none;
            background: none;
            padding: 0;

            &:disabled {
                background: none;
            }
        }

        &:nth-child(1) {
            img {
                width: 28px;
                height: 28px;
            }
        }

        &:nth-child(2) {
            img {
                width: 20px;
                height: 20px;
            }
        }

        img {
            margin: 0 4px;
            display: inline-block;
            width: 24px;
            height: 24px;
        }

        &-wrapper {
            display: flex;
            align-items: center;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-right {
            display: flex;
            align-items: center;
            background-color: #EDEDED;
            padding: 6px;
            border-radius: 25px;
        }
    }

    &__viewTab {
        display: inline-flex;
        align-items: center;
        color: #AEAEAE;
        font-size: 14px;
        font-weight: 500;
        border-radius: 18px;
        transition: 0.3s all ease-in-out;
        padding: 4px 10px;
        cursor: pointer;

        svg {
            width: 24px;
            height: 24px;
            margin-right: 8px;
        }

        &-active {
            color: #479BD2;
            background-color: #fff;
        }
    }

    &__headingBtnBox {
        display: flex;
        align-items: center;
        margin: 0 10px;
    }

    &__headingBtn {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin: 0 10px;
    }

    &__link {
        color: #6B6B6B !important;
        font-size: 16px;
        cursor: pointer;
    }

    &__createDate {
        color: #90A0B7;
        font-size: 12px;
    }

    &__legends {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__legendItem {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;

        p {
            color: #787878;
            font-weight: 500;
            font-size: 15px;
        }
    }

    &__legendColor {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 10px;
    }

    &__actionbar {
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: #fff;
        border-radius: 12px;
        padding: 14px;
        margin-top: 16px;
    }

    &__actionItem {
        color: #334D6E;
        font-size: 16px;
        font-weight: 500;
        display: inline-flex;
        align-items: center;

        &-filter {
            cursor: pointer;
        }

        span {
            color: #90A0B7;
            font-weight: 400;
        }

        &-img {
            margin-left: 6px;
            width: 10px;
            height: 10px;
        }
    }

    &__body {
        background-color: #fff;
        padding: 10px 20px;
        border-radius: 12px 12px 0 0;
        margin-top: 24px;
        flex: 1;
    }

    &__checkbox {
        color: #334D6E;
        font-size: 14px;
        font-weight: 500;
    }

    &__filter {
        color: #6C6C6C;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        margin-left: 2rem;
        display: flex;
        align-items: center;

        img {
            display: inline-block;
            width: 22px;
            height: 22px;
        }
    }

    &__bodyAction {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
    }

    &__delModal {
        margin-top: 20px;
    }

    &__btnBox {
        text-align: right;
        margin-top: 20px;
    }

    &__disabled {
        & .sp__bodyActionItem {
            opacity: 0.5;
            // cursor: not-allowed;
        }
    }

    &__active-relevance {
        background-color: #E1F3FF;
        padding: 2px;
        border-radius: 5px;
    }

    &__paginationPrompt {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(241, 243, 244, 0.871);
        padding: 6px 16px;
        min-height: 48px;
        border-radius: 8px;
        margin-top: 10px;

        &-text {
            font-size: 0.875rem;
            color: #5f6368;
            margin-right: 0.25rem;
        }

        &-btn {
            cursor: pointer;
            background-color: transparent;
            outline: none;
            font-size: 0.875rem;
            color: #479BD2;
            position: relative;
            transition-duration: .15s;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            padding: 4px 8px;
            border-radius: 4px;

            &:hover {
                background-color: rgb(71, 155, 210, 0.1);
            }
        }
    }
}