.spcard {
  &__container {
    margin: 20px 10px 0;
  }

  &__navigation {
    text-align: center;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid rgba(108, 108, 108, 0.2);
  }

  &__tabs {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 10px;
    border-bottom: 2px solid rgba(108, 108, 108, 0.2);
    ;
  }

  &__tabLink {
    font-weight: 500;
    font-size: 12px;
    margin-right: 20px;
    cursor: pointer;
    color: rgba(108, 108, 108, 0.5);

    &--active {
      color: #6C6C6C;
    }
  }

  &__tab {
    min-height: 400px;
  }
}