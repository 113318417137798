.tab {
    &__container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    &__btnBox {
        text-align: right;
        margin-top: 20px;
    }

    &__body {
        margin-top: 10px;
    }

    &__selectBox {
        min-width: 300px;
    }

    &__actionBox {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    &__imgBox {
        img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }
    }

    &__ccInputBox {
        display: flex;
        align-items: center;

        label {
            font-weight: 600;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.54);
            white-space: nowrap;
        }

        // input {
        //   width: 300px;
        //   margin-left: 10px;
        //   padding: 10px;
        // }
    }

    &__quillBox {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        .bodyWords {
            color: rgba(0, 0, 0, 0.45);
            font-size: 12px;
            text-align: end;
            margin-top: 4px;
        }
    }

    &__ccSubtitle {
        border-top: 2px solid rgba(0, 0, 0, 0.1);
        padding-top: 5px;
        font-weight: 500;
        font-size: 12px;
        color: rgba(108, 108, 108, 0.5);
        width: 400px;
    }

    &__prevBox {
        margin-top: 50px;
        border-top: 2px solid rgba(0, 0, 0, 0.1);
        padding-top: 10px;
    }

    &__prevText {
        font-weight: 500;
        font-size: 18px;
        color: rgba(108, 108, 108, 0.5);
    }

    &__emailBox {
        text-align: center;
        margin-top: 30px;
    }

    &__item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
    }

    &__profile {
        margin-right: 20px;
        padding-top: 10px;
    }

    &__desc {
        max-width: 500px;
    }

    &__img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    &__text {
        color: #6c6c6c;
        font-size: 12px;
        margin-bottom: 10px;
    }

    &__date {
        color: rgba(108, 108, 108, 0.7);
        font-size: 10px;
    }

    &__select {
        width: 300px;
        // margin-bottom: 10px;
        cursor: pointer;

        padding: 5px 10px;
        background-color: #ededed;
        border-radius: 7px;

        p {
            color: #767474;
            font-size: 14px;
            font-weight: 700;
            display: flex;
            align-items: center;
        }
    }
}

.headerContainer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
}