.wk {

  &__heading {
    color: #334D6E;
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }

  &__headingBtnBox {
    display: flex;
    align-items: center;
    margin: 0 10px;
  }

  &__headingBtn {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0 10px;
  }

  &__legends {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__legendItem {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;

    p {
      color: #787878;
      font-weight: 500;
      font-size: 15px;
    }
  }

  &__legendColor {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
  }

  &__link {
    color: #6B6B6B !important;
    font-size: 16px;
    cursor: pointer;
  }

  &__dateBox {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__createDate {
    color: #90A0B7;
    font-size: 12px;
    margin-bottom: 10px;
  }

  &__actionbar {
    display: flex;
    justify-content: space-around;
    align-items: center;

    background-color: #fff;
    border-radius: 12px;
    padding: 15px;
    margin: 30px 0;
  }

  &__actionItem {
    color: #334D6E;
    font-size: 16px;
    font-weight: 500;
    padding: 5px 8px;
    cursor: pointer;

    span {
      color: #90A0B7;
      font-weight: 400;
      margin-left: 5px;
      border-radius: 50%;
      padding: 4px 5px;
      font-size: 12px;
      background-color: #DFDFDF;
    }

    &--active {
      background-color:#F2F6FF;
      border-radius: 12px;

      span {
        background-color: #6599FF;
        color: #fff;
      }
    }
  }

  &__tooltip {
    padding: 20px;
  }

  &__toolHeading {
    font-weight: 500;
    font-size: 20px;
    color: #6B6B6B;
  }

  &__toolUserBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    p {
      font-size: 11px;
      color: #C2C2C2;
    }
  }

  &__toolBodyTitle {
    font-weight: 500;
    font-size: 15px;
    color: #B0B0B0;
    margin: 10px 0;
  }

  &__toolUl {
    list-style: inside;
    list-style-type: disc;
    padding-left: 10px;

    li {
      font-weight: 500;
      font-size: 17px;
      color: #6B6B6B;
    }
  }
}