.msgl {
  &__itemBox {
    text-decoration: none;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 10px 8px;
    border-radius: 0.5rem;

    &-active {
      background-color: #dff3f3;
    }
  }

  &__legend {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 15px;
  }

  &__img {
    width: 40px;
    height: 40px;
    margin-right: 1rem;
  }

  &__detail {
    flex: 1;
    margin-right: 15px;
  }

  &__name {
    color: #212121;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;

    span {
      color: #fff;
      font-weight: 400;
      margin-left: 5px;
      border-radius: 50%;
      font-size: 12px;
      background-color: #6599FF;
      margin-left: 10px;
      width: 24px;
      height: 24px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__msg {
    font-size: 11px;
    font-weight: 300;
    color: #212121;

    p {
      color: inherit;
    }
  }

  &__dateBox {
    align-self: flex-start;
  }

  &__date {
    font-size: 10px;
    font-weight: 300;
    color: #212121;
    white-space: nowrap;
  }
}