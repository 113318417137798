.wk {
    height: 100%;

    &__container {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        background-color: rgba(255, 255, 255, 0.7);
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;

        &Body {
            position: absolute;
            text-align: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &Title {
            color: #479bd2;
            margin-bottom: 20px;
            font-weight: 500;
        }

        &Wrapper {
            display: flex;
            align-items: stretch;
            justify-content: center;

            &Card {
                padding: 20px 30px;
                background-color: #e1f3ff;
                border: 1px solid #479bd2;
                color: #479bd2;
                border-radius: 11px;
                margin-right: 2rem;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                min-width: 200px;
                box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px,
                    rgba(0, 0, 0, 0.24) 0px 1px 2px;
                cursor: pointer;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    &__heading {
        color: #334d6e;
        font-size: 26px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    &__header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__headerRight {
        margin-left: auto;
    }

    &__createDate {
        color: #90a0b7;
        font-size: 12px;
    }

    &__body {
        border-radius: 30px;
        flex: 1;
        background-image: url("../../assets/img/edit-workflow-bg.png");
        display: flex;
        justify-content: center;
        align-items: flex-start;
        position: relative;
    }

    &__sidebar {
        width: 22.5%;
        height: 100%;
        padding-right: 20px;
        background-color: #fff;
        border-radius: 30px 0 0 0;
        padding: 1rem;
    }

    &__okBtn {
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }

    &__flowWrapper {
        margin: 0 10px;
        width: 100%;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 5px;
            background: #e2e2e2;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: #8cc0e3;
            border-radius: 4px;
        }
    }

    &__main {
        flex: 1;
        height: 100%;
    }

    &__mainBox {
        margin: 0 auto;
        max-width: 900px;
    }

    &__legendBox {
        height: 100%;
        display: flex;
        align-items: stretch;
        flex-direction: column;
    }

    &__legendWrapper {
        display: flex;
        justify-content: space-between;
        height: 100%;
    }

    &__legend {
        background: #479bd2;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 5px 10px;
        // margin: auto;
        width: fit-content;
        cursor: pointer;
    }

    &__sidebarScroll {
        max-height: calc(100vh - 177px);
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 5px;
            background: #e2e2e2;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: #8cc0e3;
            border-radius: 4px;
        }
    }

    &__searchBox {
        margin-bottom: 20px;
    }

    &__search {
        background: #f2f2f2;
        border-radius: 13px;
        padding: 10px;
        width: 100%;

        &::placeholder {
            color: #cfcfcf;
            font-size: 16px;
        }
    }

    &__item {
        margin-bottom: 0.75rem;

        &:last-child {
            margin-bottom: 0;
        }

        &:first-child {
            border-bottom: 2px solid #d9d9d9;
            padding-bottom: 0.5rem;
        }

        &-inactive {
            pointer-events: none;
            background-color: #f8f8f8;
            opacity: 0.8;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
    }

    &__itemHead {
        font-weight: 500;
        font-size: 18px;
        color: #6b6b6b;
        display: flex;
        align-self: center;
    }

    &__itemLink {
        font-size: 14px;
        color: #6b6b6b;
        padding: 8px 12px;
        border-radius: 13px;
        margin-right: 10px;

        &-disabled {
            font-size: 14px;
            color: #6b6b6b;
            padding: 8px 12px;
            border-radius: 13px;
            margin-right: 10px;

            &:hover {
                cursor: not-allowed;
            }
        }

        &:hover {
            background-color: #f8f8f8;
            cursor: move;
        }
    }

    &__confirmText {
        font-weight: 500;
        color: #334d6e;
        font-size: 20px;
        margin: 10px 0;
        text-align: center;
    }

    &__greenTick {
        width: 30px;
        height: 30px;
        margin: 0 auto;
        display: block;
    }

    &__templateLink {
        font-size: 14px;
        color: #6b6b6b;
        cursor: pointer;
        padding: 8px 12px;
        border-radius: 13px;
        margin-bottom: 8px;

        &:hover {
            background-color: #f8f8f8;
        }

        &-active {
            background-color: #f8f8f8;
        }
    }

    &__modal {
        margin-right: 20px;

        p {
            color: rgba(0, 0, 0, 0.7);
            margin: 5px 0;
        }
    }

    &__stats {
        font-weight: 500;
        font-style: normal;
        text-decoration: underline;
    }
}