.msge {
  &__container {
    background: #fff;
    border-radius: 30px;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__titlebox {
    border-bottom: 3px solid #EBEFF2;
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    color: #404040;
    padding: 20px 20px 10px 20px;
  }

  &__body {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 5px;
      background: #E2E2E2;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #8CC0E3;
      border-radius: 4px;
    }
  }

  &__itemBox {
    text-decoration: none;
  }

  &__item {
    margin-bottom: 20px;

    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }

  &__main {
    margin-right: 15px;
    flex: 1;
  }

  &__user {
    align-self: center;
    margin-right: 15px;
  }

  &__number {
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    background: #6599FF;
    border-radius: 12px;
    padding: 0 5px;
    margin-left: 5px;
  }

  &__datebox {
    display: flex;
    justify-content: flex-end;
  }

  &__date {
    color: #212121;
    font-size: 11px;
  }

  &__name {
    font-size: 16px;
    color: #404040;
    margin-bottom: 10px;
    font-weight: 500;
  }

  &__desc {
    font-size: 11px;
    color: #212121;
    font-weight: 400;
  }

  &__sendBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &__no-data {
    &-img {
      display: block;
      width: auto;
      height: 300px;
      margin: 0 auto;
    }

    &-text {
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      letter-spacing: 0.01em;
      color: rgba(146, 146, 146, 0.7);
    }
  }
}