.tmp {
  height: 100%;

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__heading {
    color: #334D6E;
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }

  &__link {
    color: #6B6B6B !important;
    font-size: 16px;
  }


  &__dateBox {
    text-align: right;
  }

  &__main {
    background-color: #fff;
    border-radius: 30px;
    flex: 1;
    margin-top: 30px;
    padding: 10px;
  }

  &__body {
    padding: 10px;
    padding-right: 20px;
    margin-right: 10px;
    max-height: 520px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
      background: #E2E2E2;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #8CC0E3;
      border-radius: 4px;
    }
  }

  &__titleBox {
    border-bottom: 2px solid #D9D9D9;
    padding-bottom: 10px;

    h3 {
      font-weight: 500;
      font-size: 18px;
      color: #4E4E4E;
    }
  }

  &__item {
    display: flex;
    align-items: flex-start;
    margin: 10px 0;
    margin-bottom: 20px;
  }

  &__checkBox {
    display: flex;
    align-items: center;
    margin: 10px 0;

    &:hover .tmp__actionBox {
      width: 70px;
    }
  }

  &__itemLeft {
    width: 40%;
    max-height: 250px;
    overflow-y: auto;
    margin: 10px;

    &::-webkit-scrollbar {
      width: 5px;
      background: #E2E2E2;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #8CC0E3;
      border-radius: 4px;
    }
  }

  &__check {
    margin-right: 20px;
    font-size: 14px;
    cursor: pointer;
  }

  &__actionWrapper {
    width: 70px;
  }

  &__actionBox {
    width: 0;
    display: flex;
    align-items: center;
    overflow: hidden;

    img {
      margin-right: 5px;
      cursor: pointer;
    }
  }

  &__itemRight {
    flex: 1;
    padding: 20px;
  }

  &__nameBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__name {
    font-weight: 600;
    font-size: 16px;
    color: #202124;
    flex: 1;
  }

  input {
    background: #F7F7F7;
    padding: 10px;
    border-radius: 12px;
    color: #202124;
    width: 90%;

    &::placeholder {
      color: rgba(32, 33, 36, 0.52);
    }
  }

  &__subjectBox {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    p:first-child {
      width: 80px;
      color: #202124;
      font-weight: 600;
    }

    p:last-child {
      color: rgba(32, 33, 36, 0.52);
      flex: 1;
    }
  }

  &__textarea {
    border: none;
    outline: none;
    resize: none;
    background: #F7F7F7;
    border: 1px solid #D3E1FF;
    border-radius: 15px;
    padding: 10px;
    height: 100px;
    width: 100%;

    &:active,
    &:focus {
      border: none;
      outline: none;
    }
  }

  &__btnBox {
    margin-top: 10px;
    text-align: right;
  }

  &__delBox {
    text-align: right;
    margin-top: 20px;
  }

  &__placeholder {
    font-size: 16px;
    font-weight: 400;
    color: rgba(32, 33, 36, 0.52);
    text-align: center;
    margin: 10px auto;
  }
}