.referralModal {
    padding: 0;
    font-family: "";
}

.referralModal .ant-modal-content {
    padding: 0;
    background-color: transparent !important;
}

// .referralModal .ant-modal-body {
//     border-radius: 1rem;
// }

.referralCodeContainer {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
    align-items: center;
    justify-content: center;
}

.referralCodeGridBackground {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
}

.referralCodeLogo {
    grid-column: 1 / 2;
    grid-row: 1 / -1;
    justify-self: center;
    padding-left: 3rem;
    padding-right: 1rem;
    border-right: 2px dashed rgba(102, 102, 102, 0.2);
}

.referralCodeContent {
    grid-column: 2 / -1;
    grid-row: 1 / -1;
    padding: 1rem;
}

.referralFormContainer {
    display: flex;
    flex-direction: column;
}

.referralFormInputsContainer {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr;
    gap: 0.5rem;
}
