.pro {
  &__container {
    margin-top: 20px;
  }

  &__aboutBox,
  &__expBox,
  &__eduBox,
  &__skillBox {
    margin-top: 30px;

    h2 {
      font-weight: 600;
      font-size: 16px;
      color: #6C6C6C;
    }
  }

  &__expImg {
    background-color: #838383;
    width: 40px;
    height: auto;
    display: inline-block;
    padding: 5px;
  }

  &__expItem {
    display: flex;
    align-items: flex-start;

    border-bottom: 2px solid #D9D9D9;
    padding: 20px 0 10px 0;
  }

  &__expDesc {
    margin-left: 20px;
  }

  &__expName {
    color: #6C6C6C;

    span {
      color: #0077B5;
      font-weight: 600;
    }
  }

  &__expDetail {
    color: #6C6C6C;
    font-size: 13px;
    margin-top: 10px;
  }

  &__expTime {
    font-weight: 500;
    font-size: 12px;
    color: #6C6C6C;
    font-weight: 300;
    display: flex;
    align-items: center;
  }

  &__expLocation {
    margin-right: 20px;
  }

  &__expSpan {
    font-weight: 600;
    display: flex;
    align-items: center;

    span {
      background: #068093;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: block;
      margin-right: 5px;
    }
  }

  &__eduBox {
    border-bottom: 2px solid #D9D9D9;
    padding-bottom: 10px;
  }

  &__eduItem {
    display: flex;
    align-items: flex-start;
    padding: 20px 0 10px 0;
  }

  &__eduDesc {
    margin-left: 20px;

    p:first-child {
      color: #6C6C6C;
    }

    p:nth-child(2) {
      text-decoration-line: underline;
      color: #0077B5;
      font-size: 12px;
    }

    p:nth-child(3) {
      color: #6C6C6C;
      font-weight: 300;
      font-size: 12px;
    }
  }

  &__eduDetail {
    font-size: 13px;
    margin-top: 10px;
  }

  &__eduImg {
    background-color: #838383;
    width: 40px;
    height: auto;
    display: inline-block;
    padding: 5px;
  }

  &__skillContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 10px 0 0 10px;
  }

  &__skillItem {
    color: #656565;
    font-size: 10px;
    border-radius: 3px;
    padding: 5px 10px;
    background-color: #efefef;
    text-align: center;
    margin: 0 15px 10px 0;
    font-weight: 500;
  }
}