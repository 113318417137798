.arrow span {
    display: block;
    width: 1.5vw;
    height: 1.5vw;
    border-bottom: 5px solid #6042DF;
    border-right: 5px solid #6042DF;
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 1s infinite;
}

.arrow span:nth-child(2) {
    animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
    animation-delay: -0.4s;
}

@keyframes animate {
    0% {
        border-color: rgba(96, 66, 223, 0);
    }

    25% {
        border-color: rgba(96, 66, 223, 0.25);
    }

    50% {
        border-color: rgba(96, 66, 223, 0.5);
    }

    75% {
        border-color: rgba(96, 66, 223, 0.75);
    }

    100% {
        border-color: rgba(96, 66, 223, 1);
    }
}