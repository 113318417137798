.home {

  height: 100%;

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__body {
    flex: 1;

    background-color: rgba(255,255,255,0.8);
    padding: 50px 25px;
    border-radius: 30px 30px 0 0;
    margin-top: 30px;

    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  &__item {
    height: 100%;
    min-width: 45%;

    &:first-child {
      margin-right: 15px;
    }
  }

  &__actionBar {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    margin: 10px 0;
  }

  &__actionText {
    color: rgba(0,0,0,0.4);
    font-size: 12px;
    margin-right: 20px;
    text-align: right;
    font-weight: 600;
  }
}