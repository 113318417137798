.addCreditInput {
    box-shadow: 0px 0px 0px 1px #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 200px;
}

.buttonContainer {
    width: 100%;
    text-align: right;
}

.sendCredits,
.sendCredits:hover {
    font-weight: 600 !important;
    border-radius: 20px !important;
    background-color: #479BD2 !important;
}