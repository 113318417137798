.inputWithWordCount {
    position: relative;
    display: flex;
    min-width: 0;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    background-color: #fff;
    background-image: none;
    border: 0;
    padding: 0;

    &::before {
        width: 0;
        visibility: hidden;
        content: "\a0";
    }

    &__count {
        color: rgba(0, 0, 0, 0.45);
        font-size: 12px;
        margin-inline-start: 4px;
        display: flex;
        flex: none;
        align-items: center;
    }
}