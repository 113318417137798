.container {
    height: 100%;
}
.trackingContainer {
    background: #fafafa;
    background-color: #ffffff;
    border-radius: 20px;
    height: 92%;
}

.dateContainer {
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
    padding: 10px;
}

.getTracking,
.getTracking:hover {
    background-color: #479BD2 !important;
    font-weight: 600 !important;
    color: #ffffff !important;
    border-radius: 30px !important;
    margin-left: 15px !important;
    margin-top: 7px !important;
}

.tackingCard {
    padding: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #f0f0f0;
}

.trackingCardItem {
    padding: 10px;
    word-wrap: break-word;
}

.bodyContainer {
    text-align: center;
    height: 95%;
    overflow-y: auto;
    padding: 10px 1px;
}

.headerContainer {
    padding: 10px;
    color: #334D6E !important;
    font-size: 18px;
    font-weight: 500 !important;
    background: #fafafa;
    border-bottom: 1px solid #f0f0f0;
    /* border-radius: 20px 20px 0px 0px; */
}

.header {
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
    text-align: center;
}

