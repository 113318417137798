.act {
  &__container {
    margin-top: 20px;
  }

  &__loader {
    margin-top: 20px;
    text-align: center;
  }

  &__actionBox {
    display: flex;
    align-items: center;
  }

  &__checkbox {
    margin-right: 30px;
  }

  &__checkboxText {
    font-weight: 500;
    font-size: 12px;
    color: rgba(108, 108, 108, 0.5);
  }

  &__userBox {
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__userImg {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }

  &__body {
    margin-top: 30px;
  }

  &__item {
    display: flex;
    align-items: center;
    margin: 20px 0;
  }

  &__itemTextBox {
    width: 60%;
  }

  &__itemText {
    font-size: 13px;
    color: #6C6C6C;

    span {
      font-weight: 600;
    }
  }

  &__date {
    font-size: 11px;
    color: rgba(108, 108, 108, 0.5);
  }
}