.naEmailAlert {
    border-radius: 0.5rem;
    margin: 0 15px;
    padding: 0.75rem;
    width: -webkit-fill-available;
    background-color: rgba(255, 211, 97, 0.35);
    position: relative;
    margin-bottom: 0.75rem;

    &__text {
        font-size: 12px;
        color: #404040;
    }

    &__star {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-left: 4px;
        transform: translateY(2px);
    }

    &__close {
        cursor: pointer;
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        display: inline-block;
        width: 14px;
        height: 14px;
    }
}