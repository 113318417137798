.orgContainer {
    width: calc(100vw - 300px);
    height: 100%;
}

.container {
    background: #fafafa;
    background-color: #ffffff;
    border-radius: 20px;
    height: 92%;

}

.bodyContainer {
    height: 95%;
    overflow-y: auto;
    padding: 10px 1px;
}

.orgCard {
    padding: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #f0f0f0;
}

.orgCardItem {
    padding: 10px;
}

.headerContainer {
    padding: 10px;
    color: #334D6E !important;
    font-size: 18px;
    font-weight: 500 !important;
    background: #fafafa;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 20px 20px 0px 0px;
}

.header {
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
}

.exportButtonContainer {
    background-color: #ffffff;
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: end;
}

.exportButton {
    margin-right: 10px !important;
}

.sortByContainer {
    background-color: #ffffff;
    width: 150px;
    float: right;
}

.link {
    margin-left: 10px;
    cursor: pointer;
    color: #479bd2 !important;
}

.nameContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.userCount {
    display: inline;
    font-size: 14px !important;
    margin-right: 15px !important;
    opacity: 0.7;
}