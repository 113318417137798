* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

html,
body,
#root {
  background: #ffffff;
  height: 100%;
}

input,
button {
  outline: none;
  border: none;
  font-family: 'Poppins', sans-serif;
}

button:disabled {
  opacity: 0.7;
}

.bg-green {
  background: #4FCA64;
}

.bg-red {
  background: #E92525;
}

.bg-gray {
  background: #8C8C8C;
}

.ql-editor {
  height: 300px !important;
}

.ant-modal-content {
  border-radius: 30px !important;
}

.ant-table-body {
  overflow-x: auto;
  margin: 5px;

  &::-webkit-scrollbar {
    width: 5px;
    background: #E2E2E2;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #8CC0E3;
    border-radius: 4px;
  }
}

.ant-table.ant-table-fixed-header {
  border-radius: 30px;
}

.ant-table-header {
  border-radius: 30px 30px 0 0 !important;
}

.ant-table-row:hover .ant-dropdown-trigger {
  visibility: visible;
}

.ant-table-row .ant-dropdown-trigger {
  visibility: hidden;
}

.ant-switch-checked {
  background-color: #088397 !important;
}

.ant-select-selector {
  font-size: 12px !important;
}

.ant-select-item {
  font-size: 12px !important;
}

#workflow-email .ql-toolbar {
  display: none !important;
}

#workflow-email .ql-container {
  border: 1px solid #ccc !important;
}

#workflow-reminder .ql-toolbar {
  display: none !important;
}

#workflow-reminder .ql-container {
  border: 1px solid #ccc !important;
}

#workflow-reminder .ql-editor {
    height: 150px !important;
    background-color: #f3f6f9 !important;
}

#workflow-email .ql-editor {
    background-color: #f3f6f9 !important;
}
