.tab {

  &__container {
    padding: 20px;
  }

  &__btnBox {
    text-align: right;
    margin-top: 20px;
  }

  &__body {
    margin-top: 10px;
  }

  &__ccInputBox {
    display: flex;
    align-items: center;

    label {
      font-weight: 600;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.54);
    }

    input {
      width: 300px;
      margin-left: 10px;
      padding: 10px;
    }
  }

  &__ccSubtitle {
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    padding-top: 5px;
    font-weight: 500;
    font-size: 12px;
    color: rgba(108, 108, 108, 0.5);
    width: 400px;
    margin-bottom: 20px;
  }


  &__quillBox {
    margin-top: 20px;
  }

  &__emailBox {
    text-align: center;
    margin-top: 30px;
  }
}