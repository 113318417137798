.spcard {
    &__skillsItem {
        color: #656565;
        font-size: 10px;
        border-radius: 3px;
        padding: 5px 10px;
        background-color: #efefef;
        text-align: center;
        margin-right: 15px;
        font-weight: 500;
    }

    &__relevantSkill {
        background-color: #E1F3FF;
    }

    &__seeMore {
        text-align: center;
        color: #656565;
        text-decoration: underline;
        text-underline-offset: 3px;
        margin-top: 4px;
    }

    &__itemRight {
        flex: 1;
    }

    &__skills {
        display: flex;
        flex-wrap: wrap;
        row-gap: 10px;
    }
}