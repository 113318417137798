.myAccount {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__tabs {
        border-top-left-radius: 1.5rem;
        border-top-right-radius: 1.5rem;
        background-color: #fff;
        padding: 10px 30px 20px;
        flex: 1;
    }
}