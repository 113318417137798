.wk {
    &__emailRecommendation {
        &-loader {
            width: 100%;
            text-align: center;

            &-text {
                margin-top: 1rem;
            }
        }
    }
}