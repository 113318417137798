.unsubscribePage {
    background-color: rgba(71, 155, 210, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;

    &__wrapper {
        border-radius: 10px;
        border: 1px solid #d6d4d4;
        background-color: #ffffff;
        padding: 45px 60px;
        width: 100%;
        max-width: 900px;
        text-align: center;
        margin: 25px auto;
    }

    &__header {
        font-size: 18px;
        line-height: 31px;
        border-bottom: 1px solid #dbdedf;
        padding-bottom: 30px;
        margin-bottom: 30px;

        >strong {
            font-size: 24px;
            font-weight: bold;
            color: #000000;
            display: inline-block;
            word-break: break-all;
        }
    }

    &__success {
        >strong {
            color: #4cbb17;
            font-size: 36px;
            display: inline-block;
        }
    }

    &__body {
        >strong {
            color: #ff3f12;
            font-size: 36px;
            display: inline-block;
            margin-bottom: 14px;
        }
    }

    &__selectReason {
        width: 100%;
        max-width: 430px;
        text-align: left;
        margin: 0 auto;
        margin-top: 35px;
    }
}