.act {

  &__container {
    margin: 20px 0;
  }

  &__topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 2px solid rgba(217, 217, 217, 0.5);
  }

  &__select {
    padding: 9px 0;
    background-color: #F3F3F3;
    border-radius: 13px;

    span {
      color: #757575;
      font-size: 16px;
      font-weight: 500 !important;
    }
  }

  &__filterBox {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  &__filter {
    width: 33.333%;
  }

  &__item {
    display: flex;
    margin: 8px 0;

    p {
      width: 33.333%;

      font-weight: 500;
      font-size: 16px;
      color: #404040;
      padding: 0 11px;
    }

    p:last-child {
      font-weight: 400;
      font-size: 14px;
      color: #C0C0C0;
    }
  }
}